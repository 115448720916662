.show_score_button {
  display: block;
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 1em;
  padding: 0.2em;
  height: 2.4em;
  border-radius: 10%;
  cursor: pointer;
}

.show_score_button_medal {
  transform-origin: top;
  animation: medalSwing 5s ease-in-out infinite;
}

.show_score_button_x {
  height: 3em;
  padding: 0;
  top: 0.8em;
  right: 0.8em;
}

.hidden {
  display: none;
}

@keyframes medalSwing {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
}
