.loading_pokeball_wrapper {
  background: white;
  display: flex;
  flex-direction: column;
  place-items: center;
  height: 100vh;
  width: 100vw;
  place-content: center;
  border-radius: unset;
  place-self: center;
}

.loading_pokeball {
  animation: rotate 1.5s linear infinite;
}

.ellipsis_dot_one {
  display: inline-block;
  animation: blink_dot_one 1.5s infinite;
}

.ellipsis_dot_two {
  display: inline-block;
  animation: blink_dot_two 1.5s infinite;
}

.ellipsis_dot_three {
  display: inline-block;
  animation: blink_dot_three 1.5s infinite;
}

@keyframes rotate {
  10% {
    transform: rotate(-10deg);
  }

  15% {
    transform: rotate(0deg);
  }
}

@keyframes blink_dot_one {
  0% {
    visibility: hidden;
  }
  25% {
    visibility: collapse;
  }
}

@keyframes blink_dot_two {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: collapse;
  }
}

@keyframes blink_dot_three {
  0% {
    visibility: hidden;
  }
  75% {
    visibility: collapse;
  }
}
