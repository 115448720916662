.generationScale {
  margin: 0.4rem;
}

.sliderWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5em;
}

input[type="range"]::-webkit-slider-runnable-track {
  border-radius: 45px;
  background: linear-gradient(90deg, var(--poke-yellow), var(--poke-red));
}

input[type="range"]::-webkit-slider-thumb {
  border-radius: 45px;
  background: linear-gradient(90deg, var(--poke-yellow), var(--poke-red));
}

input[type="range"]::-moz-range-progress {
  border-radius: 45px;
  background: linear-gradient(90deg, var(--poke-yellow), var(--poke-red));
}

input[type="range"]::-moz-range-track {
  border-radius: 45px;
  background: linear-gradient(90deg, var(--poke-yellow), var(--poke-red));
}
