.answer_input {
  padding: 0.5em;
  margin: 0em 1em;
  border: transparent 0.5em solid;
}

.answer_input_correct {
  border: green 0.5em solid;
}
.answer_input_incorrect {
  border: var(--poke-red) 0.5em solid;
}
