.answer_buttons {
  text-align: center;
  padding: 1em;
  color: white;
  cursor: pointer;
  background: var(--poke-blue);
  border: solid transparent 10px;
}

.answer_buttons_selected {
  border: solid green 10px;
}

.answer_buttons_correct {
  cursor: default;
  background: green;
}

.answer_buttons_incorrect {
  cursor: default;
  background: var(--poke-red);
}

.answer_buttons_post_selection {
  cursor: default;
}
