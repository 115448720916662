.indicators_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.title_button {
  color: white;
  background: var(--poke-blue);
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.round_indicator {
  display: flex;
  margin: 1em;
}
