.final_page_score {
  display: flex;
  place-content: center;
  font-size: 4em;
}

.final_page_image {
  margin: 1em;
  width: 90vw;
  max-width: 324px;
  max-height: 50vh;
}

.final_page_image_wrapper {
  display: flex;
  place-content: center;
}
