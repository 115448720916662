.instructions_continue_button {
  align-self: center;
  color: #fff;
  background-color: var(--poke-dark-red);
  padding: 1em;
  margin: 1em 2em 3em;
  cursor: pointer;
}

.instructions_continue_button_text {
  padding-bottom: 5px;
  font-family: var(--main-text-font);
  font-weight: lighter;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
