.answer_confirm_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.answer_input_wrapper {
  margin-top: 3em;
  font-family: var(--secondary-text-font);
}
