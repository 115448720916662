.quiz_pokemon_image_wrapper {
  display: flex;
  justify-content: center;
  background-color: black;
  margin: 0em 1em 1em;
}

.quiz_pokemon_image {
  width: 100%;
}
