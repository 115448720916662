.App {
  text-align: center;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-color: var(--poke-yellow);
  font-family: var(--secondary-text-font);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 35em;
  justify-content: space-evenly;
}
