.settings_wrapper {
  display: flex;
  flex-direction: column;
  place-content: space-around;
  margin: 0em 1em;
  padding: 1em;
  height: 100%;
}

.genLabel {
  padding: 0.5em 0em;
  color: var(--poke-yellow);
  font-weight: normal;
}

.show_settings_button {
  display: block;
  position: absolute;
  z-index: 2;
  top: 1em;
  left: 1em;
  padding: 0.2em;
  height: 2.4em;
  border-radius: 10%;
  cursor: pointer;
}

.show_score_button_cog:hover {
  animation: cogRotate 5s ease-in-out infinite;
}

.show_settings_button_x {
  height: 3em;
  padding: 0;
  top: 0.8em;
  left: 0.8em;
}

.hidden {
  display: none;
}

@keyframes cogRotate {
  50% {
    transform: rotate(90deg);
  }
}
