.quiz_confirm_button {
  background: var(--poke-red);
  color: white;
  padding: 0.8em;
  cursor: pointer;
}

.quiz_confirm_button_text {
  font-family: var(--main-text-font);
  animation: blink 1.5s infinite;
}

.quiz_confirm_next_wrapper {
  display: flex;
  padding: 1em;
  align-self: flex-end;
  justify-content: flex-end;
}
