* {
  margin: 0px;
  padding: 0px;

  --poke-yellow: #ffde00;
  --poke-dark-yellow: #b3a125;
  --poke-red: #ff0000;
  --poke-dark-red: #cc0000;
  --poke-blue: #3b4cca;

  --main-text-font: "Press Start 2P";
  --secondary-text-font: "Trocchi";
  border-radius: 10px;
  border: none;
  outline: none;
}

html {
  display: flex;
  place-content: center;
}
