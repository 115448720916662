.toggles_wrapper {
  display: flex;
}

.show_toggles_wrapper {
  position: absolute;
  flex-direction: column;
  text-align: center;
  place-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  background: var(--poke-blue);
  color: white;
}
